import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/seo';
import Container from '../components/container/container';
import Typography from '../components/typography/typography';
import Button from '../components/button/button';

const NotFoundPage = () => (
  <>
    <SEO
      title="Strona nie istnieje"
      meta={[{ name: 'robots', content: 'noindex, follow' }]}
    />

    <Container>
      <Typography variant="h1">Strona nie istnieje</Typography>

      <Button component={Link} to="/" css="margin-top: 50px;">
        Wróć do strony głównej
      </Button>
    </Container>
  </>
);

export default NotFoundPage;
