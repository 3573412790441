import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, url }) {
  const {
    site,
    file: { publicURL },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }

        file(name: { eq: "promen-logo" }) {
          publicURL
        }
      }
    `
  );

  const {
    siteMetadata: { siteUrl },
  } = site;

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[{ rel: 'canonical', href: `${siteUrl}${url}` }]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: `${siteUrl}${url}`,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: `${site.siteMetadata.siteUrl}${publicURL}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {`
          {
            "@context":"http://schema.org",
            "@type":"LocalBusiness",
            "name":"PRO-MEN S.C.",
            "image":"${site.siteMetadata.siteUrl}${publicURL}",
            "@id":"",
            "url":"",
            "telephone":"501 029 798",
            "email":"info@promen.com.pl",
            "address":{
              "@type":"PostalAddress",
              "streetAddress":"Radawiec Duży 83B",
              "addressLocality":"Motycz",
              "postalCode":"21-030",
              "addressCountry":""
            }
          }
        `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  keywords: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SEO;
